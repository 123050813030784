<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Administradores</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-row>
          <v-col md="8" cols="12">
            <v-btn
              title="Crear nuevo"
              color="primary"
              class="mt-2"
              small
              depressed
              @click="handleClickNew"
            >
              nuevo
            </v-btn>
          </v-col>
        </v-row>

        <p class="caption pasive--text text-right">
          {{ items.length }} administradores
        </p>

        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="items.length"
          disable-sort
          no-data-text="Debe agregar una especialidad..."
          item-class="text-center"
          class="my-5"
          hide-default-footer
        >
          <template slot="item.lastLogin" scope="props">
            {{ formatDateAndTime(props.item.lastLogin) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showForm"
      width="900"
      persistent
      @keydown.esc="showForm = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <AdminForm
          ref="AdminFormRef"
          @created="showForm = false"
          @updated="showForm = false"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SearchInput from "../components/SearchInput.vue";
import AdminForm from "../components/Admin/AdminForm.vue";
import moment from "moment";

export default {
  name: "Eadmins",
  components: {
    SearchInput,
    AdminForm,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      deleting: false,
      terms: "",
      items: [],
      headers: [
        { text: "ID", value: "id", align: "center" },
        { text: "Nombre", value: "firstName", align: "left" },
        { text: "Apellido", value: "lastName", align: "left" },
        { text: "Correo", value: "email", align: "left" },
        { text: "Último login", value: "lastLogin", align: "left" },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "admins"]),
  },
  methods: {
    ...mapActions(["fetchAdmins"]),
    ...mapMutations(["setAlert", "removeAdmin"]),
    formatDateAndTime(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },

    handleClickNew() {
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.AdminFormRef.title = "Crear";
        this.$refs.AdminFormRef.setFormValues({ form: null });
      });
    },

    async getData() {
      this.loading = true;
      await this.fetchAdmins({ terms: this.terms });
      this.loading = false;
    },
  },
  watch: {
    admins: function (value) {
      this.items = value || [];
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
